<template>
  <div class="videoItem">
      <div @click="setVideoSelected" class="clickable-div-button">
        <div class="thumbnail">
          <img :src="thumbnail" alt="thunmbnail" />
        </div>
        <div class="details">
          <div class="avatar">
            <img :src="channelAvatar" alt="ChannelAvatar" />
          </div>
          <div class="videoInfo">
            <div class="videoTitle">
              <h3 class="title_header">{{ videoTitle }}</h3>
            </div>
            <div class="metadata">
              <div class="channelName">
                <p>{{ channelName }}</p>
              </div>
              <div class="postDate">
                <p>{{ postedSince }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
// import { computed } from "@vue/reactivity";

export default {
  props: ["post", "index"], // link
  computed: {
    postIndex() {
      return this.$vnode.key;
    },
    thumbnail() {
      return this.post.body.body.bottomRow.link.imageUrl;
    },
    videoLink() {
      return this.post.body.body.bottomRow.link.externalUrl;
    },
    videoId() {
      return this.post.body.body.bottomRow.link.text.subtitle;
    },
    videoTitle() {
      return this.post.body.body.bottomRow.link.text.title;
    },
    postedSince() {
      const postedDate = new Date(
        this.post.body.header.rightColumn.bottomRow.postedSince
      );
      const now = new Date();
      const timeDiff = now - postedDate;
      const seconds = Math.floor(timeDiff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const months = Math.floor(days / 30);
      const years = Math.floor(months / 12);

      if (seconds <= 60) return `${seconds} seconds ago`;
      if (minutes <= 60) return `${minutes}  minutes ago`;
      if (hours <= 24) return `${hours}  hours ago`;
      if (days <= 30) return `${days}  days ago`;
      if (months <= 12) return `${months}  months ago`;
      return `${years} years ago`;
      // return "Years ago"
    },
    videoViews() {
      return "TODO fix"; // youtube apicall doesnt return video views
    },
    channelName() {
      return this.post.body.header.rightColumn.bottomRow.posterName;
    },
    channelAvatar() {
      return this.post.body.header.leftColumn.imageUrl;
    },
  },
  methods: {
    setVideoSelected() {
      window.scrollTo(0,0)
      this.$store.commit("youtube/SET_VIDEO_SELECTED", this.videoId);
      this.$store.commit("youtube/SET_VIDEO_SELECTED_INDEX", this.postIndex);
      this.$store.commit("youtube/SET_SELECTED_POST", this.post);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/youtube-styles.scss";

.videoItem {
  height: auto;
  width: 19%;
  h3.title_header {
    max-height: 2.5em;
    overflow: hidden;
  }
  
  display: flex;
  

  .clickable-div-button {
    cursor: pointer;
  }

  .thumbnail {
    width:100%;
    height: calc((100%*8.9)/16 - 4%);
    img {
      height: calc(100% - 0px);
      width: 100%;
      border-radius: 10px;
      object-fit: cover;
      
    }
  }

  .details {
    display: flex;
    font-family: $videoItem-videoTitle-font-family;
    font-weight: 500;

    .avatar {
      margin: 12px 12px 0px 0px;
      width: $videoItem-avatar-desktop-width;
      height: $videoItem-avatar-desktop-height;
      border-radius: 50%;

      img {
        object-fit: cover;
        border-radius: 50%;
        width: $videoItem-avatar-desktop-width;
        height: $videoItem-avatar-desktop-height;
      }
    }
    .videoInfo {
      margin: 12px 12px 0px 0px;
      padding: 0px 26px 0px 0px;

      .videoTitle {
        h3 {
          font-size: 16px;
        }
      }

      .metadata {
        display: flex;
        flex-direction: column;
        .postDate,
        .channelName {
          display: inline-block;
          p {
            margin: 0px 0px 0px 0px;
            font-size: 14px;
            color: $videoDetails-color;
          }
        }
      }
    }
  }
}
@media screen and (max-width:1550px) {
  .videoItem{
    width: calc(24% - 10px);
    
  }
}
@media screen and (max-width:1200px) {
  .videoItem{
    width: calc(31% - 20px);
    margin-bottom: -20px;
  }
  
}
@media screen and (max-width:1000px) {
  .videoItem{
    width: calc(30%);
    margin-bottom: -25px;
  }
  
}
@media screen and (max-width:950px) {
  .videoItem{
    width: calc(50% - 50px);
    margin-bottom: -20px;
  }
  
}
@media screen and (max-width:768px) {
  .videoItem{
    width: calc(50% - 15px);
    margin-bottom: -20px;
  }
  
}

@media screen and (max-width:500px) {
  .videoItem{
    width: 100%;
    margin-bottom: -35px;
  }

}






</style>
