<template>
  <div id="top-row-inner">
    <div class="start">
      <div class="owner">
        <a>
          <div class="avatar">
            <img :src="channelAvatar" alt="" />
          </div>
        </a>
        <div class="channelInfo">
          <div class="channelName">
            <a href=""> {{ channelName }} </a>
          </div>
          <div class="subscribers">
            <span>
              {{ subscribers }}K {{ $t("page.youtube.subscribers") }}</span
            >
          </div>
        </div>
        <div id="subscribe-btn">
        <button>{{ $t("page.youtube.subscribe") }}</button>
      </div>
      </div>
    </div>
    <div id="actions" class="end">
      <div class="like-dislike-btn">
        <button id="like-btn" class="action-btn">
          <img src="../../assets/img/Youtube/like-icon.png" alt="Like" />
          <span>{{ videoLikes }}</span>
        </button>

        <button id="dislike-btn" class="action-btn">
          <img src="../../assets/img/Youtube/dislike-icon.png" alt="Dislike" />
        </button>
      </div>
      <div class="share-btn">
        <button class="action-btn">
          <img src="../../assets/img/Youtube/share.png" alt="" />
          <span>{{ $t("page.youtube.share") }}</span>
        </button>
      </div>
      <div class="other-btn">
        <button class="action-btn">
          <img src="../../assets/img/Youtube/action_other.png" alt="" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("youtube", {
      post: "getSelectedPost",
    }),
    channelName() {
      return this.post.body.header.rightColumn.bottomRow.posterName;
    },
    channelAvatar() {
      return this.post.body.header.leftColumn.imageUrl;
    },
    videoTitle() {
      return this.post.body.body.bottomRow.link.text.title;
    },
    thumbnail() {
      return this.post.body.body.bottomRow.link.imageUrl;
    },
    videoLink() {
      return this.post.body.body.bottomRow.link.externalUrl;
    },
    videoLikes() {
      const likes = this.post.body.footer.reactionsCounter;
      const thousand = Math.floor(likes / 1000);
      if (thousand <= 0) return `${likes}`;
      return `${thousand} K`;
    },
    subscribers() {
      return this.post.body.subscribers;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/youtube-styles.scss";
a {
  text-decoration: none;
  color: inherit;
}
#top-row-inner {
  display: flex;
  flex-wrap: wrap;
  color: $youtube-text-primary-color;
  font-family: $videoItem-videoTitle-font-family;
  width: inherit;
  .start {
    display: flex;
    justify-self: start;
  }

  .owner {
    display: flex;
    margin: 6px 12px 0px 0px;
    .avatar {
      margin: 0px 12px 0px 0px;
      width: $videoItem-avatar-desktop-width;
      height: $videoItem-avatar-desktop-height;
      border-radius: 50%;
      img {
        object-fit: cover;
        border-radius: 50%;
        width: $youtube-video-content-avatar-width;
        height: $youtube-video-content-avatar-height;
      }
    }
    .channelInfo {
      display: flex;
      flex-direction: column;
      min-width: 110px;
      .channelName {
        font-size: 16px;
        margin: 0px -1.6px 0px 0px;
        padding: 0 1.6px 0px 0px;
      }
    }
    .subscribers {
      font-size: 12px;
      margin: 0px 4px 0px 0px;
      color: $videoDetails-color;
    }
  }
  #subscribe-btn {
    display: flex;
    align-items: center;
    button {
      width: $youtube-video-content-subscribe-btn-width;
      height: $youtube-video-content-subscribe-btn-height;
      border-radius: 30px;
      background-color: $youtube-subscribe-btn-color-black;
      color: $youtube-subscribe-btn-text-color-light;
      font-size: 14px;
      font-weight: bold;
      border: none;
    }
  }
  #actions {
    width: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    img {
      width: $youtube-desktop-small-icon-width;
      height: $youtube-desktop-small-icon-height;
    }
    button {
      border: none;
    }
    .action-btn {
      background-color: #fff;
    }
    .like-dislike-btn {
      font-size: 14px;

      display: flex;
      padding: 10px;
      #like-btn {
        height: 36px;
        width: 90px;
        border: 1px solid $header-border-color-light;
        border-radius: 40px 0px 0px 40px;
        img {
          margin-bottom: 3px;
        }
        span {
          margin-left: 5px;
          font-family: $videoItem-videoTitle-font-family;
          font-weight: bolder;
        }
      }
      #dislike-btn {
        height: 36px;

        width: 52px;
        border: 1px solid $header-border-color-light;
        border-radius: 0px 40px 40px 0;
        img {
          margin-top: 3px;
        }
      }
    }
    .share-btn {
      padding: 10px;
      display: flex;
      button {
        height: 36px;
        padding: 0px 16px;
        border-radius: 40px 40px 40px 40px;
        border: 1px solid $header-border-color-light;
      }
      span {
        margin-left: 5px;
      }
    }
    .other-btn {
      button {
        height: 36px;
        border-radius: 40px 40px 40px 40px;
        border: 1px solid $header-border-color-light;
      }
    }
  }
}
</style>
